// import Img from "../../loader.gif";

const Loader = () => {
  return (
    <div className="loading" style={{ width: "100%", textAlign: "center" }}>
      <h2>Loading data</h2>
      <h5>This can take a couple of seconds...</h5>
    </div>
  );
};

export default Loader;
