import styled from "styled-components";

export const getUrlParameter = (paramName) => {
  let paramValue = null;
  let params = new URLSearchParams(window.location.search);

  for (let p of params) {
    if (p[0].toLocaleLowerCase() === paramName) {
      paramValue = p[1];
    }
  }

  return paramValue;
};

export const upperCaseText = (t) => {
  return t ? t.toString().toUpperCase() : "";
};

export const getFullAddress = (address, st, city, zip) => {
  let r =
    upperCaseText(address) +
    " " +
    upperCaseText(city) +
    " " +
    upperCaseText(st) +
    " " +
    zip;

  return r
    .replace(" WEST ", " W ")
    .replace(" NORTH ", " N ")
    .replace(" EAST ", " E ")
    .replace(" SOUTH ", " S ")
    .replace(" AVENUE ", " AVE ")
    .replace(" BOULEVARD ", " BLVD ")
    .replace(" #", " APT ")
    .replace(" UNIT ", " APT ")
    .replace(".", "")
    .replace(",", "")
    .replace("  ", " ");
};

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;

  &:hover {
    cursor: pointer;
  }
`;

export const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextField
      id="search"
      type="text"
      placeholder="Filter By Name"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
  </>
);
