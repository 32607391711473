import React, { useContext, useState } from "react";
import { UserContext } from "../../App";
import { query, sfGetCSVFromDropbox, csvArraysToObject } from "../API";
import Loader from "../Loader";
import { Container, Card } from "react-bootstrap";
import { readString } from "react-papaparse";
import { FilterComponent, getFullAddress, getUrlParameter } from "../Utils";
import DataTable from "react-data-table-component";
import { useEffect } from "react";

const ClientList = () => {
  const [data, setData] = useState(null);
  const [mailingListAddresses, setMailingListAddresses] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const context = useContext(UserContext);

  context.gargleRequestKey = "4c590b60-5163-4d7a-9ed8-88f0d73a71b2";

  const filteredMailingListAddresses = mailingListAddresses.filter(
    (item) =>
      item.FullAddress &&
      item.FullAddress.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const orderid = getUrlParameter("orderid");
  useEffect(() => {
    if (!data && orderid) {
      query(
        "Order",
        "Id, Order_Label__c, Dropbox_Folder__c",
        "Id='" + orderid + "'",
        context
      ).then((order) => {
        setData(order[0]);
      });
    }
  });

  if (data && data.Dropbox_Folder__c && !isLoaded) {
    sfGetCSVFromDropbox(context, data.Dropbox_Folder__c)
      .then((mlCsv) => {
        if (mlCsv) {
          const ml = csvArraysToObject(readString(mlCsv).data);
          for (const r of ml) {
            r.order = data;
            r.FullAddress = getFullAddress(
              r.addressl
                ? r.addressl
                : r.address1
                ? r.address1
                : r.ADDRESS
                ? r.ADDRESS
                : r.ADDRES
                ? r.ADDRES
                : r.ADDRESS1
                ? r.ADDRESS1
                : r.address
                ? r.address
                : r.LINE2
                ? r.LINE2
                : r.line2
                ? r.line2
                : r.Addres
                ? r.Addres
                : r.Address,
              r.st ? r.st : r.state ? r.state : r.STATE,
              r.city ? r.city : r.CITY,
              r.zip ? r.zip : r.ZIP
            );
          }
          console.log(ml);
          setMailingListAddresses(ml);
        }
        setIsLoaded(true);
      })
      .catch((e) => {
        setIsLoaded(true);
      });
  }

  return (
    <>
      {!orderid && <>Missing Order Id</>}
      {orderid && (
        <div className="content" style={{ margin: "0", padding: "40px" }}>
          {(!data || mailingListAddresses.length === 0) && <Loader />}
          {data && mailingListAddresses.length > 0 && (
            <Container fluid>
              <Card.Header>All Mailing List Addresses</Card.Header>
              <Card.Body>
                {mailingListAddresses.length > 0 && (
                  <DataTable
                    pagination
                    highlightOnHover
                    columns={[
                      {
                        name: "Mailing List Address",
                        selector: (row) => row.FullAddress,
                        wrap: true,
                        grow: 2,
                      },
                      {
                        name: "Order Label",
                        selector: (row) => row.order.Order_Label__c,
                        sortable: true,
                      },
                    ]}
                    data={filteredMailingListAddresses}
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    paginationResetDefaultPage={resetPaginationToggle}
                  />
                )}
              </Card.Body>
            </Container>
          )}
        </div>
      )}
    </>
  );
};

export default ClientList;
