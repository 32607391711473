import axios from "axios";

const serverUrl = "https://webapi.startupp.cloud/access/WebAPI/";
const devServerUrl = "https://localhost:44302/access/WebAPI/";
const isDev = false;

const dentalMarketingRequestKey = "4d3d57e1-1d9b-4f4b-9f64-16574f211c27";

const authorize = async (context) => {
  return new Promise((resolve, reject) => {
    const sParams = {
      requestKey: dentalMarketingRequestKey,
      action: "authorize",
    };

    try {
      if (!context.AUTH_CODE) {
        axios
          .post(isDev ? devServerUrl : serverUrl, sParams)
          .then((response) => {
            if (response) {
              context.AUTH_CODE = response.data.authorization;
              resolve(context.AUTH_CODE);
            }
          })
          .catch((error) => {
            context.AUTH_CODE = "";
            reject(context.AUTH_CODE);
          });
      } else {
        resolve(context.AUTH_CODE);
      }
    } catch (err) {
      context.AUTH_CODE = "";
      reject(context.AUTH_CODE);
    }
  });
};

export const query = async (obj, fields, filter, context) => {
  try {
    const AUTH_CODE = await authorize(context); // Requests an authorization code before making actual calls

    const sParams = {
      requestKey: dentalMarketingRequestKey ? dentalMarketingRequestKey : null,
      requestCode: AUTH_CODE,
      action: "sf-search",
      search: [
        {
          sobject: obj,
          fields: fields,
          filter: filter,
        },
      ],
    };

    const { data } = await axios.post(
      isDev ? devServerUrl : serverUrl,
      sParams
    );

    const response = data;
    return response[obj];
  } catch (err) {
    return null;
  }
};

// CODE DENTALMARKETING-IMPORT-PATIENTS

export const sfGetCSVFromDropbox = async (context, folder) => {
  const AUTH_CODE = await authorize(context); // Requests an authorization code before making actual calls

  const sParams = {
    requestKey: dentalMarketingRequestKey,
    requestCode: AUTH_CODE,
    action: "dropbox-search",
    folders: [
      {
        folder: decodeURIComponent(
          folder
            .replace("https://www.dropbox.com/home", "")
            .replace("https://www.dropbox.com/work/Client Services", "")
            .replace("https://www.dropbox.com/work/Client%20Services", "")
        ),
        fileType: ".csv",
      },
    ],
  };

  const { data } = await axios.post(isDev ? devServerUrl : serverUrl, sParams);
  const response = data;
  return response["file-blob"] ? response["file-blob"].toString() : null;
};

export const csvArraysToObject = (csvArray) => {
  const cols = csvArray.shift();
  const result = [];

  for (const line of csvArray) {
    const obj = {};
    for (let i = 0; i < line.length; i++) {
      obj[cols[i]] = line[i];
    }
    result.push(obj);
  }

  return result;
};
